import * as React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Stack,
  Button,
  Flex,
  Box,
  Heading,
} from '@chakra-ui/react';
import { ToastContainer, toast } from 'react-toastify';
import { Auth, API } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { updateUserAttributes } from '../../../state/user/userSlice';
import { useAppDispatch } from '../../../state/createStore';
import { changeAccountName } from '../../../state/account/account';

interface AccountDetailsInput {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
}

const ChangeAccountInfo = (): JSX.Element => {
  const { currentAccount } = useSelector((state: any) => state.account);
  const { user } = useSelector((state: any) => state.user);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountDetailsInput>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const onSubmit = async (data: AccountDetailsInput) => {
    // Auth.change
    setLoading(true);
    if (data.companyName !== currentAccount.companyName) {
      try {
        const token = (await Auth.currentSession())
          .getAccessToken()
          .getJwtToken();
        const res = await API.patch(
          'openscreen',
          `accounts/${currentAccount?.accountId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              companyName: data.companyName,
            },
          }
        );
        dispatch(changeAccountName(res.companyName));
      } catch (error) {
        console.log(error);
      }
    }

    try {
      const newUserObject = {
        email: data.email,
        family_name: data.lastName,
        given_name: data.firstName,
        // companyName: data.companyName,
      };
      const curUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(curUser, newUserObject);
      dispatch(updateUserAttributes(newUserObject));
      toast.success('Account details changed.');
      setLoading(false);
    } catch (error) {
      toast.error(`Account details could not be changed. ${error.message}`);
      setLoading(false);
    }
  };

  return (
    <Stack maxW="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column">
          <Heading marginBottom="10px" fontSize="20pt">
            Account
          </Heading>
          <Box marginLeft="10px">
            <Flex direction={{ base: 'column', md: 'row' }}>
              <FormControl marginRight="40px" id="firstName">
                <FormLabel>First Name</FormLabel>
                <Input
                  variant="filled"
                  height="50px"
                  defaultValue={user?.given_name}
                  {...register('firstName', {})}
                  type="text"
                  placeholder="First Name"
                />
                <FormHelperText color="red.500">
                  {errors.firstName && <span>This field is required</span>}
                </FormHelperText>
              </FormControl>{' '}
              <FormControl id="lastName">
                <FormLabel>Last Name</FormLabel>
                <Input
                  height="50px"
                  {...register('lastName', {})}
                  variant="filled"
                  defaultValue={user?.family_name}
                  type="text"
                  placeholder="Last Name"
                />
                <FormHelperText color="red.500">
                  {errors.lastName && <span>This field is required</span>}
                </FormHelperText>
              </FormControl>
            </Flex>
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input
                variant="filled"
                height="50px"
                defaultValue={user?.email}
                {...register('email', {})}
                type="text"
                placeholder="Login Email"
              />
              <FormHelperText color="red.500">
                {errors.email && <span>This field is required</span>}
              </FormHelperText>
            </FormControl>
            <FormControl id="Company Name">
              <FormLabel>Company Name</FormLabel>
              <Input
                variant="filled"
                height="50px"
                defaultValue={currentAccount?.companyName}
                {...register('companyName', {})}
                type="text"
                placeholder="Company Name"
              />
              <FormHelperText color="red.500">
                {errors.email && <span>This field is required</span>}
              </FormHelperText>
            </FormControl>
            <Button
              alignSelf="flex-end"
              maxW="300px"
              borderRadius="55px"
              paddingX="25px"
              paddingY="25px"
              isLoading={loading}
              bgColor="#4fc1ff"
              color="#fff"
              _hover={{
                bgColor: '#000',
              }}
              marginY="10px"
              type="submit"
            >
              Update Account Info
            </Button>
          </Box>
        </Flex>
      </form>
      <ToastContainer theme="colored" />
    </Stack>
  );
};

export default ChangeAccountInfo;
