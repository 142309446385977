import * as React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import BasicInfoSection from './sections/basicInfoSection/basicInfoSection';
import ChangePasswordForm from '../forms/changePassword/changePassword';

const AccountProfile = (): JSX.Element => {
  return (
    <Flex direction="column">
      <Flex direction="column">
        <Box
          marginX="40px"
          bgColor="#fff"
          height="100%"
          w={{ base: '80%', md: '70vw' }}
          padding="20px"
        >
          <BasicInfoSection />
        </Box>
      </Flex>
      <Box
        bgColor="#fff"
        height="100%"
        margin="40px"
        w={{ base: '80%', md: '70vw' }}
        padding="20px"
      >
        <Heading fontSize="20pt" marginBottom="20px">
          Change Password
        </Heading>
        <Box marginLeft="20px">
          <ChangePasswordForm />
        </Box>
      </Box>
    </Flex>
  );
};

export default AccountProfile;
