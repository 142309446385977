import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import ChangeAccountInfo from '../../../forms/changeAccountInfo/changeAccountInfo';

const BasicInfoSection = (): JSX.Element => {
  return (
    <Flex minH="300px" height="100%" justify="space-evenly" direction="column">
      <Flex
        minH="300px"
        height="100%"
        justify="space-evenly"
        direction="column"
      >
        <ChangeAccountInfo />
      </Flex>
    </Flex>
  );
};

export default BasicInfoSection;
