import * as React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Flex,
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface AccountFormInput {
  oldPass: string;
  newPass: string;
  confirmPass: string;
}

const ChangePasswordForm = (): JSX.Element => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AccountFormInput>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });
  const password = React.useRef({});
  password.current = watch('newPass', '');

  const submit = async (
    data: AccountFormInput,
    e: { target: { reset: () => void } }
  ) => {
    try {
      e.target.reset();
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, data.oldPass, data.newPass);
      toast.success('Password Changed');
    } catch (error) {
      toast.error('Error changing password');
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Flex direction="column">
        <FormControl id="currentPassword" isRequired>
          <FormLabel>Current Password</FormLabel>
          <Input
            height="50px"
            {...register('oldPass', {
              required: true,
            })}
            type="password"
            variant="filled"
            placeholder="Password"
          />
          <FormHelperText color="red.500">
            {errors.oldPass && <span>This field is required</span>}
          </FormHelperText>
        </FormControl>
        <FormControl marginBottom="10px" id="newPassword" isRequired>
          <FormLabel>New Password</FormLabel>
          <Input
            height="50px"
            variant="filled"
            {...register('newPass', {
              required: true,
              minLength: 8,
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
            })}
            type="password"
            placeholder="Password"
          />
          <FormHelperText color="red.500">
            {errors.newPass && <span>This field is required</span>}
            <FormHelperText marginLeft="20px" color="black">
              <span>
                Password should be at least 8 characters with a combination of
                lowercase and uppercase characters.
              </span>
            </FormHelperText>
          </FormHelperText>
        </FormControl>{' '}
        <FormControl id="confirmNewPassword" isRequired>
          <FormLabel>Confirm Password</FormLabel>
          <Input
            variant="filled"
            height="50px"
            {...register('confirmPass', {
              required: true,
              validate: (value) =>
                value === password.current || 'The passwords do not match',
            })}
            type="password"
            placeholder="Confirm Password"
          />
          <FormHelperText color="red.500">
            {errors.confirmPass && <span>Make sure your passwords match!</span>}
          </FormHelperText>
        </FormControl>
        <Button
          alignSelf="flex-end"
          borderRadius="55px"
          paddingX="25px"
          paddingY="25px"
          bgColor="#4fc1ff"
          color="#fff"
          _hover={{
            bgColor: '#000',
          }}
          marginY="10px"
          type="submit"
        >
          Change My Password
        </Button>
      </Flex>
      <ToastContainer theme="colored" />
    </form>
  );
};

export default ChangePasswordForm;
